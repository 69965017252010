import React from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Iframe from "react-iframe"
import HeroBar from "../components/hero-bar"

const CalendarPage = () => (
  <Layout>
    <SEO title="Calendar" />
    <HeroBar subtitle="Events Calendar" />
    <div className="columns has-margin-top-15">
      <div className="column is-offset-1 is-10 has-text-centered has-margin-top-15 has-margin-bottom-15">
        <Iframe
          url="https://calendar.google.com/calendar/embed?title=BN%20%3D%20Bishop%20Neumann%20%2F%2F%20STJ%20%3D%20St.%20John%27s%20%2F%2F%20STW%20%3D%20St.%20Wenceslaus&&wkst=1&bgcolor=%23FFFFFF&src=cavalrybasketball%40gmail.com&color=%238D6F47&ctz=America%2FChicago"
          className="is-hidden-mobile cav-calendar"
          display="initial"
        />
        <Iframe
          url="https://calendar.google.com/calendar/embed?title=BN%20%3D%20Bishop%20Neumann%20%2F%2F%20STJ%20%3D%20St.%20John%27s%20%2F%2F%20STW%20%3D%20St.%20Wenceslaus&&wkst=1&bgcolor=%23FFFFFF&src=cavalrybasketball%40gmail.com&color=%238D6F47&ctz=America%2FChicago&mode=AGENDA"
          className="is-hidden-tablet cav-calendar"
          display="initial"
        />
      </div>
    </div>
  </Layout>
)

export default CalendarPage
